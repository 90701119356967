/* eslint-disable @next/next/no-img-element */
"use client";

import { basePath } from "@/next.config";
import {
  auth,
  googleProvider,
  signInWithEmailAndPassword,
  signInWithPopup,
  sendPasswordResetEmail,
} from "@/shared/firebase/firebaseClient";
import Link from "next/link";
import { useRouter } from "next/navigation";
import { useState, ChangeEvent, FormEvent, useEffect } from "react";
import Head from "next/head";

export default function Home() {
  const [passwordshow1, setpasswordshow1] = useState(false);
  const [err, setError] = useState<string>("");
  const [data, setData] = useState({
    email: "",
    password: "",
  });
  const { email, password } = data;

  const router = useRouter();

  useEffect(() => {
    // Prevent back-button navigation to a protected session
    history.replaceState(null, document.title, window.location.pathname);
  }, []);

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [e.target.name]: e.target.value });
    setError("");
  };

  const Login = async (e: FormEvent) => {
    e.preventDefault();
    try {
      const user = await signInWithEmailAndPassword(auth, email, password);
      console.log(user);
      RouteChange();
    } catch (err) {
      if (err instanceof Error) {
        setError(err.message);
      } else {
        setError("An unknown error occurred.");
      }
    }
  };

  const googleLogin = async () => {
    try {
      const result = await signInWithPopup(auth, googleProvider);
      console.log(result);
      RouteChange();
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("An unknown error occurred.");
      }
    }
  };

  const resetPassword = async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault(); // Prevent default link behavior
    if (!email) {
      setError("Please enter your email address to reset your password.");
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      alert("Password reset email sent! Please check your inbox.");
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      } else {
        setError("An unknown error occurred.");
      }
    }
  };

  const RouteChange = () => {
    const user = auth.currentUser;
    if (user) {
      user.getIdTokenResult().then((idTokenResult) => {
        const role = idTokenResult.claims.role;
        if (role === "admin") {
          router.push(`/admin/${user.uid}/miearnings`);
        } else if (role === "referrer") {
          router.push(`/referrer/${user.uid}/earnings`);
        } else if (role === "publisher") {
          router.push(`/publisher/${user.uid}/earnings`);
        } else {
          router.push("/");
        }
      });
    } else {
      router.push("/");
    }
  };

  return (
    <>
      <Head>
        <title>Sign In - Headerbiddr Dashboard</title>
        <meta
          name="description"
          content="Maximize your Ad Revenue with Headerbiddr"
        />
        <meta property="og:url" content="https://dashboard.headerbiddr.com" />
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content="HeaderBiddr - Elite Ad Performance"
        />
        <meta
          property="og:description"
          content="Maximize your Ad Revenue with Headerbiddr"
        />
        <meta
          property="og:image"
          content="https://opengraph.b-cdn.net/production/images/58ab8781-bba4-4533-a4a1-cd0d87b4f5a9.png"
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta property="twitter:domain" content="dashboard.headerbiddr.com" />
        <meta
          property="twitter:url"
          content="https://dashboard.headerbiddr.com"
        />
        <meta
          name="twitter:title"
          content="HeaderBiddr - Elite Ad Performance"
        />
        <meta
          name="twitter:description"
          content="Maximize your Ad Revenue with Headerbiddr"
        />
        <meta
          name="twitter:image"
          content="https://opengraph.b-cdn.net/production/images/58ab8781-bba4-4533-a4a1-cd0d87b4f5a9.png"
        />
      </Head>

      <div className="container">
        <div className="flex justify-center authentication authentication-basic items-center h-full text-defaultsize text-defaulttextcolor">
          <div className="grid grid-cols-12">
            <div className="xxl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-3 sm:col-span-2"></div>
            <div className="xxl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-6 sm:col-span-8 col-span-12">
              <div className="my-[0.1rem] flex justify-center">
                <Link href="/">
                  <img
                    src={`${
                      process.env.NODE_ENV === "production" ? basePath : ""
                    }/assets/images/brand-logos/toggle-dark.png`}
                    alt="logo"
                    className="desktop-logo"
                    width={150}
                    height={150}
                  />
                  <img
                    src={`${
                      process.env.NODE_ENV === "production" ? basePath : ""
                    }/assets/images/brand-logos/toggle-dark.png`}
                    alt="logo"
                    className="desktop-dark"
                    width={150}
                    height={150}
                  />
                </Link>
              </div>
              <div className="box !p-[1rem]">
                <div className="box-body">
                  <p className="h5 font-semibold mb-2 text-center">Sign In</p>
                  {err && (
                    <div
                      className="p-4 mb-4 bg-danger/40 text-sm border-t-4 border-danger text-danger/60 rounded-lg"
                      role="alert"
                    >
                      {err}
                    </div>
                  )}
                  <p className="mb-4 text-[#8c9097] opacity-[0.7] font-normal text-center">
                    Welcome back!
                  </p>
                  <div className="grid grid-cols-12 gap-y-4">
                    <div className="xl:col-span-12 col-span-12">
                      <label
                        htmlFor="signin-email"
                        className="form-label text-default"
                      >
                        Email
                      </label>
                      <input
                        type="text"
                        name="email"
                        className="form-control form-control-lg w-full !rounded-md"
                        id="signin-email"
                        onChange={changeHandler}
                        value={email}
                        autoComplete="email"
                      />
                    </div>
                    <div className="xl:col-span-12 col-span-12 mb-2">
                      <label
                        htmlFor="signin-password"
                        className="form-label text-default"
                      >
                        Password
                        <Link
                          href="#"
                          className="float-right text-danger"
                          onClick={resetPassword}
                        >
                          Forget password?
                        </Link>
                      </label>
                      <div className="input-group">
                        <input
                          name="password"
                          type={passwordshow1 ? "text" : "password"}
                          value={password}
                          onChange={changeHandler}
                          className="form-control !border-s form-control-lg !rounded-s-md"
                          id="signin-password"
                          placeholder="password"
                          autoComplete="current-password"
                        />
                        <button
                          onClick={() => setpasswordshow1(!passwordshow1)}
                          className="ti-btn ti-btn-light !rounded-s-none !mb-0"
                          type="button"
                        >
                          <i
                            className={`${
                              passwordshow1 ? "ri-eye-line" : "ri-eye-off-line"
                            } align-middle`}
                          ></i>
                        </button>
                      </div>
                    </div>
                    <div className="xl:col-span-12 col-span-12 grid mt-0">
                      <button
                        onClick={Login}
                        className="ti-btn ti-btn-primary !bg-primary !text-white !font-medium"
                      >
                        Sign In
                      </button>
                    </div>
                  </div>
                  <div className="text-center mt-4">
                    <p>
                      Don't have an account?{" "}
                      <Link href="#!" className="text-primary">
                        Sign Up
                      </Link>
                    </p>
                  </div>
                  <div className="text-center my-4 authentication-barrier">
                    <span>OR</span>
                  </div>
                  <div className="text-center">
                    <button
                      onClick={googleLogin}
                      className="gsi-material-button"
                    >
                      <span>Sign in with Google</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="xxl:col-span-4 xl:col-span-4 lg:col-span-4 md:col-span-3 sm:col-span-2"></div>
          </div>
        </div>
      </div>
    </>
  );
}
